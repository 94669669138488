import React from "react"
import styled from "styled-components"
import tw from "twin.macro"
const Card = styled.div`
  ${tw`flex flex-col items-center h-full mr-4 px-2 py-8`}

  .imageContainer {
    ${tw`border text-center rounded-full p-5 flex-shrink-0`}
    img {
      ${tw`w-auto h-auto`}
    }
  }

  .textContainer {
    ${tw`sm:ml-4 mt-4 sm:mt-2`}
  }

  .title {
    ${tw`mt-4 tracking-wide font-bold text-2xl text-pointant_dark leading-none text-center`}
  }

  .description {
    ${tw`mt-1 sm:mt-4 font-medium text-pointant_grey leading-loose text-center lg:text-left`}
  }
`
const ServiceCard = ({ image, title, text}) => {
  return (
    <Card>
      <span className="imageContainer">
        <img src={image} alt="" />
      </span>
      <span className="textContainer">
        <span className="title">{title}</span>
        <p className="description">
          {text}
        </p>
      </span>
    </Card>
  )
}

export default ServiceCard