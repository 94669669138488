import React from "react"
// import ImageIDCard from 'images/pointant/id_card.png'
// import KioskLargeImage from 'images/pointant/kiosk_L.png'
// import KioskSmallImage from 'images/pointant/kiosk_S.png'
import { 
  Heading,
  // Heading, LayoutContainer, NormalGridLayout, ImageColumn, IllustrationContainer, Image, Paragraph, Subsection, DarkSection, 
  Section, 
  Subsection} from "./Misc"
// import DeviceCard from "./DeviceCard"
import tw from "twin.macro"


// const defaultDevices = [
//   {
//     title:"多功能自助服務機（大）",
//     img:KioskLargeImage,
//     deviceDimensions:"闊：480mm, 深：380mm, 高：1486-1589mm",
//     screenDimensions:"15.6吋 1920x1080像素 (16：9)",
//     barcodeScanner:"可掃描條碼及二維碼",
//   },
//   {
//     title:"多功能自助服務機（小）",
//     img:KioskSmallImage,
//     deviceDimensions:"長：266mm, 闊：228mm, 深：40mm",
//     screenDimensions:"10.1吋 1280x800像素 (16：10)",
//     barcodeScanner:"可掃描條碼及二維碼",
//   },
// ]

// const Column50 = tw(ImageColumn)`lg:w-1/2 items-center self-stretch justify-center lg:px-6 `
// const PCenter = tw(Paragraph)``

const CustomSection = tw(Section)`items-center justify-center`
const CustomSubsection = tw(Subsection)`flex justify-center`

const ContactUsForm = ({ 
  id="contactus", 
  // devices=defaultDevices,
  // title="多功能自助服務機", 
  // imgSrc=ImageIDCard,
  // imgAlt="Card Scan", 
  // text="學生可於多功能自助服務機掃瞄學生證上的條碼，即可查詢積分紀錄、簽到，及簽退。"
  formURL = "https://docs.google.com/forms/d/e/1FAIpQLSdVvgqom12IL8Z3IM4S1V8JLZcSDZ9J4hisDSBgTCaB8LGYWA/viewform",
  formTitle = "",
  formWidth = 640,
  formHeight = 1660
}) => {
  return (
    <CustomSection id={id} 
    // style={{minHeight: formHeight+8}}
    >
      <Heading>聯繫顧問</Heading>
      <CustomSubsection>
        <iframe src={formURL} width={formWidth} height={formHeight} frameborder="0" marginheight="0" marginwidth="0" title={formTitle}>載入中…</iframe>

      </CustomSubsection>
    </CustomSection>
  )
}

export default ContactUsForm