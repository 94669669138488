
import tw from "twin.macro"
import styled from "styled-components"
import { ReactComponent as SvgDecoratorBlob1 } from "../../images/svg-decorator-blob-1.svg"





export const DecoratorBlob1 = styled(SvgDecoratorBlob1)`
${tw`pointer-events-none opacity-5 absolute left-0 bottom-0 h-64 w-64 transform -translate-x-2/3 -z-10`}
`

export const Section = tw.div`relative m-0 py-6 lg:py-10 px-4 sm:px-8`
export const DarkSection = tw(Section)`bg-pointant_white`
export const Subsection = tw(Section)`my-2 lg:my-6  md:my-8`
export const NormalGridLayout = tw.div`flex lg:max-w-screen-xl flex-col lg:flex-row items-center mx-auto`
export const ReverseGridLayout = tw(NormalGridLayout)`flex-col-reverse lg:flex-row`


export const TextColumn = tw.div`relative my-6 lg:my-0 lg:last:ml-8 lg:first:mr-8 lg:w-5/12 text-center lg:text-left`

export const ImageColumn = tw.div`relative my-6 lg:my-0 flex-1 flex flex-col justify-center lg:self-end`

export const Image = tw.img`min-w-0 w-auto lg:w-full lg:max-w-lg xl:max-w-3xl`

export const Heading = tw.h1`font-bold text-3xl md:text-3xl lg:text-4xl xl:text-5xl text-pointant_accent leading-tight`
export const SubHeading = tw.h2`font-bold text-2xl md:text-2xl lg:text-3xl xl:text-4xl text-pointant_dark leading-tight`

export const IllustrationContainer = tw.div`flex justify-center lg:justify-end items-center`

export const Paragraph = tw.p`my-5 lg:my-8 text-base xl:text-2xl text-pointant_grey`




export const LinkContainer = tw.div`mt-4 mb-6 lg:mt-10`
export const Link = tw.a`text-pointant_white cursor-pointer shadow-lg w-full sm:w-auto text-sm lg:text-lg xl:text-xl px-4 py-2 lg:px-8 lg:py-3 rounded-full font-bold border border-transparent tracking-wide transition duration-300 focus:outline-none focus:shadow-outline hocus:text-pointant_grey`;
// export const ColouredLink = styled.a(({colour})=>[
//   tw`
//     text-pointant_white shadow-lg w-full sm:w-auto text-sm lg:text-lg xl:text-xl px-4 py-2 lg:px-8 lg:py-3 mt-4 first:mt-4 mt-4 mr-8 last:mr-0 rounded-full font-bold border border-transparent tracking-wide transition duration-300 focus:outline-none focus:shadow-outline hocus:bg-pointant_purple hocus:text-pointant_grey
//   `,
//   tw`bg-${colour}`
// ])



export const QuarterColumn = tw.div`lg:w-1/4 max-w-lg`
export const LayoutContainer = tw.div`flex flex-col items-stretch content-center lg:flex-row flex-wrap lg:justify-center max-w-screen-xl mx-auto`



