import React from "react"

/* framer-motion and useInView here are used to animate the sections in when we reach them in the viewport
 */
import {
  //  AnimatePresence,
   motion } from "framer-motion"

import { useInView } from 'react-intersection-observer'

const AnimationReveal = ({ disabled, children,ids }) => {
  if (disabled) {
    return <>{children}</>
  }

  if (!Array.isArray(children)) children = [children]

  const directions = ["left", "right"]
  const childrenWithAnimation = children.map((child, i) => {
    const id = ids&&ids.length&&i<ids.length&&ids[i]
    return (
      <AnimatedSlideInComponent i={i} key={i} id={id} direction={directions[i % directions.length]}>
        {child}
      </AnimatedSlideInComponent>
    )
  })
  return <>{childrenWithAnimation}</>
}

const AnimatedSlideInComponent = ({i, direction = "left", offset = 30, children , id}) => {
  const { ref, inView,  } = useInView({
    /* Optional options */
    // threshold: new Array(101).fill(0).map((v, i) => i * 0.01),
    triggerOnce: true,
    // rootMargin: '0px -20px',
    threshold: 0.1
  })
  // const [ref, inView] = useInView(30)

  // const x = { target: "0%" }

  // if (direction === "left") x.initial = "-150%"
  // else x.initial = "150%"
  const x = {
    target: "0%",
    initial: direction==="left"?"10%":"-10%"
  }
  return (
    <div ref={ref} id={id} className="animation-anchor">
      <motion.section
        initial={{ 
          x: x.initial, 
          opacity: 0 }}
        // animate={{
        //   x: inView && x.target,
        //   transitionEnd: {
        //     x: inView && 0
        //   }
        // }}
        animate={{
          x:inView?x.target:x.initial,
          opacity: inView?1:0,
        }}
        transition={{ 
          // x: { type: "spring", damping: 100, duration: 2 },
          // default: { duration: 1 },
        }}
        // ref={ref}
      >
        {children}
      </motion.section>
    </div>

  )
}
const AnimationRevealPage = props => (
    <AnimationReveal {...props} />
)


export default AnimationRevealPage