import React from "react"
import tw from "twin.macro"
import { Heading, Section, 
  // LayoutContainer, 
  Paragraph, 
  // ImageColumn, 
  NormalGridLayout, TextColumn, Link } from "./Misc"

const PlainText = tw.span`text-pointant_white`
const Column = tw(TextColumn)`self-stretch mx-4 lg:w-7/12`
const ColumnRight = tw(Column)`lg:w-5/12 flex justify-center items-center`
const Container = tw(NormalGridLayout)`flex bg-pointant_main rounded mx-auto py-2 lg:py-16 px-8 lg:px-16  text-center lg:text-left my-0`
const BookLink = tw(Link)`bg-pointant_accent`
const CustomSection = tw(Section)`mt-2 p-0 bg-pointant_main`
const BookDemonstration = ({ 
  id="book", 
  title="預約線上示範", 
  linkText="聯繫顧問", 
  linkURL="/#contactus",//"mailto:sales@animaetech.com",
  description="您可立刻聯絡我們免費預約線上示範，我們將展示我們的產品，如何幫助您更有效管理學生積分。",
}) => {
  return (
    <CustomSection id={id}>
      <Container>
        <Column>
          <Heading><PlainText>{title}</PlainText></Heading>
          <Paragraph><PlainText>{description}</PlainText></Paragraph>
        </Column>
        <ColumnRight>
          <BookLink href={linkURL}>{linkText}</BookLink>
        </ColumnRight>
      </Container>
    </CustomSection>
  )
}

export default BookDemonstration