import React from "react"
import { motion } from "framer-motion"
import tw from "twin.macro"
import styled from "styled-components"
import { css } from "styled-components/macro" //eslint-disable-line

import useAnimatedNavToggler from "../../helpers/useAnimatedNavToggler.js"

import logo from "../../images/logo192.png"
import { ReactComponent as MenuIcon } from "feather-icons/dist/icons/menu.svg"
import { ReactComponent as CloseIcon } from "feather-icons/dist/icons/x.svg"

const Header = tw.header`
  flex justify-between items-center
  max-w-screen-xl mx-auto  px-4 sm:px-8
  mt-8
`

export const NavLinks = tw.div`inline-block`

/* hocus: stands for "on hover or focus"
 * hocus:bg-primary-700 will apply the bg-primary-700 class on hover or focus
 */
export const NavLink = tw.a`
  text-lg my-2 lg:text-base xl:text-lg lg:mx-2 xl:mx-4 lg:my-0
  font-semibold tracking-wide transition duration-300
  pb-1 border-b-2 border-transparent text-pointant_dark
`

export const PrimaryLink = tw(NavLink)`
  lg:mx-0
  px-8 py-3 rounded bg-pointant_accent text-pointant_dark
  hocus:text-pointant_grey focus:shadow-outline
  border-b-0
`

export const LogoLink = styled(NavLink)`
  ${tw`flex items-center font-black border-b-0 text-xl! lg:text-2xl! xl:text-3xl! ml-0!`};

  img {
    ${tw`w-10 mr-3`}
  }
`

export const MobileNavLinksContainer = tw.nav`flex flex-1 items-center justify-between`
export const NavToggle = tw.button`
  lg:hidden z-20 focus:outline-none hocus:text-pointant_dark transition duration-300
`
export const MobileNavLinks = motion.custom(styled.div`
  ${tw`lg:hidden z-10 fixed top-0 inset-x-0 mx-4 my-6 p-8 border text-center rounded-lg text-pointant_dark bg-white`}
  ${NavLinks} {
    ${tw`flex flex-col items-center`}
  }
`)

export const DesktopNavLinks = tw.nav`
  hidden lg:flex flex-1 justify-between items-center
`

const defaultNavLinks = [
  {
    label: "特色",
    url: "/#feature"
  },
]

const HeaderNavbar = ({ 
  roundedHeaderButton = true, 
  logoLink,
  navLinks = defaultNavLinks,
  signInURL = "https://app.class.pointant-app.com/sign-in",
  signInText = "登入",
  className,
  collapseBreakpointClass = "lg",
  logoText = "Pointant Class",
  logoImageSrc= logo,
  logoURL = "/",
}) => {

  const { showNavLinks, animation, toggleNavbar } = useAnimatedNavToggler()
  const collapseBreakpointCss = collapseBreakPointCssMap[collapseBreakpointClass]

  const defaultLogoLink = (
    <LogoLink href={logoURL}>
      <img src={logoImageSrc} alt={logoText}/>
      {logoText}
    </LogoLink>
  )

  logoLink = logoLink || defaultLogoLink
  const navLinkComponents = (
    <NavLinks>
      {navLinks.map((navLink,index)=>(
        <NavLink key={index} href={navLink.url}>{navLink.label}</NavLink>
      ))}
      <PrimaryLink tw="lg:ml-6!" css={roundedHeaderButton && tw`rounded-full`} href={signInURL}>{signInText}</PrimaryLink>
    </NavLinks>
  ) 

  return (
    <Header className={className || "header-light"}>
      <DesktopNavLinks css={collapseBreakpointCss.desktopNavLinks}>
        {logoLink}
        {/* {links} */}
        {navLinkComponents}
      </DesktopNavLinks>

      <MobileNavLinksContainer css={collapseBreakpointCss.mobileNavLinksContainer}>
        {logoLink}
        <MobileNavLinks initial={{ x: "150%", display: "none" }} animate={animation} css={collapseBreakpointCss.mobileNavLinks}>
          {/* {links} */}
          {navLinkComponents}
        </MobileNavLinks>
        <NavToggle onClick={toggleNavbar} className={showNavLinks ? "open" : "closed"}>
          {showNavLinks ? <CloseIcon tw="w-6 h-6" /> : <MenuIcon tw="w-6 h-6" />}
        </NavToggle>
      </MobileNavLinksContainer>
    </Header>
  )
}

/* The below code is for generating dynamic break points for navbar.
 * Using this you can specify if you want to switch
 * to the toggleable mobile navbar at "sm", "md" or "lg" or "xl" above using the collapseBreakpointClass prop
 * Its written like this because we are using macros and we can not insert dynamic variables in macros
 */

const collapseBreakPointCssMap = {
  sm: {
    mobileNavLinks: tw`sm:hidden`,
    desktopNavLinks: tw`sm:flex`,
    mobileNavLinksContainer: tw`sm:hidden`
  },
  md: {
    mobileNavLinks: tw`md:hidden`,
    desktopNavLinks: tw`md:flex`,
    mobileNavLinksContainer: tw`md:hidden`
  },
  lg: {
    mobileNavLinks: tw`lg:hidden`,
    desktopNavLinks: tw`lg:flex`,
    mobileNavLinksContainer: tw`lg:hidden`
  },
  xl: {
    mobileNavLinks: tw`lg:hidden`,
    desktopNavLinks: tw`lg:flex`,
    mobileNavLinksContainer: tw`lg:hidden`
  }
}
export default HeaderNavbar