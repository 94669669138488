import React from "react"
import tw, {styled} from "twin.macro"
// import styled from "styled-components"
import { css } from "styled-components/macro" //eslint-disable-line
import { 
  // Heading, 
  Link, 
  // Paragraph 
} from "./Misc"

const Card = styled.div`
  ${tw`my-2 lg:mt-16 lg:mx-6 text-center px-8 rounded-lg shadow relative pt-4 pb-6 text-pointant_dark bg-white flex flex-col flex-grow`}
  .planHighlight {
    ${tw`rounded-t-lg absolute top-0 inset-x-0 h-2`}
  }
`

const CardHeader = styled.div`
  ${tw`flex flex-col uppercase leading-relaxed py-2 lg:py-2 my-2`}
  @media (min-width: 1024px){
    min-height: 6.5rem;
  }
  .title {
    ${tw`font-bold text-base lg:text-lg text-pointant_dark`}
  }
  .caption {
    ${tw`text-base lg:text-base xl:text-lg text-pointant_grey`}
  }
  img {
    min-height:200px;
    @media (min-width: 1024px){
      min-height: 500px;
    }
  }
`
const CardFeature = styled.div`
  ${tw`block my-2 lg:my-4 text-pointant_dark`}
  @media (min-width: 1024px){
    min-height: 1.5em
  }
  .feature {
    ${tw`font-medium text-xs lg:text-base xl:text-lg`}
    &:not(.mainFeature) {
      ${tw`text-pointant_grey block lg:inline ml-1 lg:ml-0`}
    }
  }
  .mainFeature {
    ${tw`font-bold tracking-wide min-w-6 lg:min-w-8 text-base lg:text-lg xl:text-xl `}
  }
`

const highlightGradientsCss = [
  css`
    background: rgb(56, 178, 172);
    background: linear-gradient(115deg, rgba(56, 178, 172, 1) 0%, rgba(129, 230, 217, 1) 100%);
  `,
  css`
    background: rgb(56, 178, 172);
    background-image: linear-gradient(115deg, #6415ff, #7431ff, #8244ff, #8e56ff, #9a66ff);
  `,
  css`
    background: rgb(245, 101, 101);
    background: linear-gradient(115deg, rgba(245, 101, 101, 1) 0%, rgba(254, 178, 178, 1) 100%);
  `
]

const ContactUsButton = tw.div`my-2 lg:my-8`

const PlanCard = ({
  index,
  name,
  targetAudience,
  pricePrepaidOneYear,
  pricePrepaidOneYearPreferential,
  studentAccountLimit,
  teacherAccountLimit,
  appVersions,
  extraFeatures= [],
  contactUs= "聯繫顧問",
}) => {
  return (
  <Card>
  <div className="planHighlight" css={highlightGradientsCss[index%highlightGradientsCss.length]} />
    <CardHeader>
      <span className="title">{name}</span>
      <span className="caption">{targetAudience.split('\n').map((item,i)=><span key={i}>{item}<br/></span>)}</span>
    </CardHeader>
    <div tw='self-center'>
      <ContactUsButton>
        <Link tw="bg-pointant_accent mr-0" href="/#contactus">
          {contactUs}
        </Link>
      </ContactUsButton>
      <CardFeature>
        <span >{studentAccountLimit} </span>{" "}
      </CardFeature>
      <CardFeature>
        <span >{teacherAccountLimit} </span>{" "}
      </CardFeature>
      <CardFeature>
        <span >{appVersions} </span>{" "}
      </CardFeature>
      {
        extraFeatures.map((feature,i)=>(
          <CardFeature key={i}>
            <span >{feature} </span>{" "}
          </CardFeature>
        ))
      }
    </div>
  </Card>
  )
}

export default PlanCard