import React from "react"
import tw from "twin.macro"
import styled from "styled-components"
import { css } from "styled-components/macro" //eslint-disable-line

const Card = styled.div`
  ${tw`my-2 lg:mt-16 lg:mx-6 text-center px-8 rounded-lg shadow relative pt-4 pb-6 text-pointant_dark bg-white flex flex-col`}
  .planHighlight {
    ${tw`rounded-t-lg absolute top-0 inset-x-0 h-2`}
  }
`

const CardHeader = styled.div`
  ${tw`flex flex-col uppercase leading-relaxed py-2 lg:py-8`}
  .caption {
    ${tw`font-bold text-lg lg:text-3xl`}
  }
  img {
    min-height:200px;
    @media (min-width: 1280px){
      min-height: 500px;
    }
  }
`
const CardFeature = styled.div`
  ${tw`flex my-2 lg:my-4 first:mt-4 lg:first:mt-8 lg:last:mb-8 text-left text-pointant_dark text-xs lg:text-base xl:text-lg`}
  .feature {
    ${tw`font-medium`}
    &:not(.mainFeature) {
      ${tw`text-pointant_grey block lg:inline ml-1 lg:ml-0`}
    }
  }
  .mainFeature {
    ${tw`font-bold tracking-wide min-w-5 lg:min-w-7`}
  }
`

const highlightGradientsCss = [
  css`
    background: rgb(56, 178, 172);
    background: linear-gradient(115deg, rgba(56, 178, 172, 1) 0%, rgba(129, 230, 217, 1) 100%);
  `,
  css`
    background: rgb(56, 178, 172);
    background-image: linear-gradient(115deg, #6415ff, #7431ff, #8244ff, #8e56ff, #9a66ff);
  `,
  css`
    background: rgb(245, 101, 101);
    background: linear-gradient(115deg, rgba(245, 101, 101, 1) 0%, rgba(254, 178, 178, 1) 100%);
  `
]

const DeviceCard = ({
  deviceName,
  deviceDimensionsValue,
  screenDimensionsValue,
  barcodeScannerValue,
  imgSrc,
  index=0,
  imgAlt=null,
  deviceDimensionsLabel="整體尺寸",
  screenDimensionsLabel="顯示器",
  barcodeScannerLabel="條碼掃描器",
}) => {
  return (
  <Card>
    <div className="planHighlight" css={highlightGradientsCss[index%highlightGradientsCss.length]} />
    <CardHeader>
      {imgSrc &&
        <img className='h-80' tw="min-w-0 w-full self-center xl:max-w-3xl my-4 object-scale-down" src={imgSrc} alt={imgAlt || deviceName}/>
      }
      <span className="caption">{deviceName}</span>
    </CardHeader>
    <div tw='self-center'>
      <CardFeature>
        <div className="inline-block feature mainFeature">{deviceDimensionsLabel}</div>
        <span className="feature">
          {deviceDimensionsValue}
        </span>
      </CardFeature>
      <CardFeature>
        <div className="inline-block feature mainFeature">{screenDimensionsLabel}</div>
        <span className="feature">
          {screenDimensionsValue}
        </span>
      </CardFeature>
      <CardFeature>
        <div className="inline-block feature mainFeature">{barcodeScannerLabel}</div>
        <span className="feature">
          {barcodeScannerValue}
        </span>
      </CardFeature>
    </div>
  </Card>
  )
}

export default DeviceCard