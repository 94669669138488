import React from "react"
import CardBatch from "../../images/pointant/card_batch.png"
import CardRealtime from '../../images/pointant/card_realtime.png'
import CardReports from '../../images/pointant/card_reports.png'
import CardMultiLingual from '../../images/pointant/card_multilingual.png'
import CardMultiChannel from '../../images/pointant/card_multichannel.png'
import CardResponsive from '../../images/pointant/card_responsive.png'
import CardSupport from '../../images/pointant/card_support.png'
import CardThirdParty from '../../images/pointant/card_thirdparty.png'
import { Heading, Section, QuarterColumn, LayoutContainer } from "./Misc"
import ServiceCard from "./ServiceCard"
const defaultCards = [
  {
    title:"快捷批次處理",
    text:"批次匯入學生資料以更新資料庫，或同時為多位學生派發積分及兌換積分，簡化重複性高的工作。",
    img: CardBatch,
  },
  {
    title:"即時更新資料庫",
    text:"資料能即時更新至雲端資料庫，讓所有帳戶透過網頁版或手機應用程式即時查閱最新的資料。",
    img: CardRealtime,
  },
  {
    title:"實時數據及報告工具",
    text:"轉化實時數據到圖表，方便迅速檢視成效。亦可將資料及數據匯出成報告，下載至儲存空間以作備份或其他用途。",
    img: CardReports,
  },
  {
    title:"支援多種語系",
    text:"系統介面支援繁體中文及英文雙語切換，讓不同國籍的老師都可以使用系統。",
    img: CardMultiLingual,
  },
  {
    title:"多渠道登入",
    text:"可隨時隨地透過網頁版或手機應用程式登入管理系統。",
    img: CardMultiChannel,
  },
  {
    title:"響應式設計",
    text:"在各種尺寸的裝置下，系統介面都能呈現合適比例。",
    img: CardResponsive,
  },
  {
    title:"多元化技術支援",
    text:"提供一站式技術支援服務，包括：顧問、客製化、軟硬件安裝、技術培訓、上門檢查維修等。",
    img:CardSupport,
  },
  {
    title:"第三方整合",
    text:"以API串接及SSO 單一帳戶登入與第三方系統進行整合，一體化管理不同的系統。",
    img:CardThirdParty,
  },
]
const Services = ({ id="services", cards=defaultCards,title="服務與功能特色"}) => {
  return (
    <Section id={id} data-aos="fade-in">
      <Heading>{title}</Heading>
      <LayoutContainer>
        {
          cards.map((card,i)=>(<QuarterColumn key={i}><ServiceCard image={card.img} title={card.title} text={card.text}/></QuarterColumn>))
        }
      </LayoutContainer>
    </Section>
  )
}

export default Services