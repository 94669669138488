import React from "react"
// import tw from "twin.macro"
import AnimationRevealPage from "helpers/AnimationRevealPage.js"
import Banner from "components/pointant_class/Banner.js"
import MainFeatures from "components/pointant_class/MainFeatures"
import Services from "components/pointant_class/Services"
// import Features from "components/features/ThreeColWithSideImage.js"
// import Pricing from "components/pricing/ThreePlans.js"
// import Footer from "components/pointant_class/Footer.js"
// import SupportIconImage from "images/support-icon.svg"
// import ShieldIconImage from "images/shield-icon.svg"
// import CustomizeIconImage from "images/customize-icon.svg"
// import Device1Image from 'images/device1.png'
// import Device2Image from 'images/device2.png'
// import PlanWithSpec from 'components/pricing/PlanWithSpec'
import HeaderNavbar from "components/pointant_class/HeaderNavbar.js"
import Devices from "./Devices"
import Plans from "./Plans"
import BookDemonstration from "./BookDemonstration"
import Footer from "./Footer"
import tw from "twin.macro"
import ContactUsForm from "./ContactUsForm"

const navLinks = [
  {
    url: "/#features",
    label: "主要功能"
  },
  {
    url: "/#services",
    label: "服務與功能特色"
  },
  {
    url: "/#devices",
    label: "多功能自助服務機"
  },
  {
    url: "/#plans",
    label: "管理系統方案"
  },
  {
    url: "/#demo",
    label: "預約線上示範"
  },
]
// const HighlightedText = tw.span`text-primary-500`

const App = tw.div`font-display min-h-screen text-pointant_grey overflow-hidden`
const LandingPage = (props) => {


  return (
    <App className="App">
        <HeaderNavbar
          navLinks={navLinks}
          />
        <Banner id="banner"/>
        <AnimationRevealPage ids={["features","services","devices","plans","demo","contactus"]}>
          <MainFeatures id={null}/>
          <Services id={null}/>
          <Devices id={null}/>
          <Plans id={null}/>
          <BookDemonstration id={null}/>
          <ContactUsForm id={null}/>
        </AnimationRevealPage>
        <Footer />
    </App>
  )
}


export default LandingPage