import React from "react"
import ReportScreensImage_2 from "../../images/pointant/report_screens_2.png"
import { Image, ImageColumn, SubHeading, Subsection, TextColumn, ReverseGridLayout, IllustrationContainer, Paragraph } from "./Misc.js"

const PunchCard = ({
  imgSrc=ReportScreensImage_2,
  imgAlt="Attendance Reports", 
  title="打卡系統",
  text="系統支援學生拍卡及老師手動打卡。校方可在各個課室設立多功能自助服務機，讓學生於進入課室時掃瞄學生證以作簽到，及於離開課室時再掃瞄學生證以作簽退。老師可透過系統手動打卡、查看及匯出個別學生最新的打卡簽到和簽退紀錄。"}) => {
  return (
    <>
      <Subsection>
        <ReverseGridLayout>
          <TextColumn>
            <SubHeading>
              {title}
            </SubHeading>
            <Paragraph>
              {text}
            </Paragraph>
          </TextColumn>
          <ImageColumn>
            <IllustrationContainer>
              <Image src={imgSrc} alt={imgAlt} />
            </IllustrationContainer>
          </ImageColumn>
        </ReverseGridLayout>
      </Subsection>
    </>
  )
}

export default PunchCard