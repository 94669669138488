import 'styles/tailwind.css'
// import 'styles/styles.css'
import { createBrowserHistory } from 'history'
import './App.css'
import LandingPage from 'components/pointant_class/LandingPage'
import GlobalThemeProvider from 'styles/GlobalThemeProvider'
import { Switch, BrowserRouter, Redirect, Route } from 'react-router-dom'
const browserHistory = createBrowserHistory()
function App() {

  window.onload = () => {
    setTimeout(()=>{
      var hash = window.location.hash
      if (hash) {
        var element = document.getElementById(hash.slice(1))
        if (element) {
          element.scrollIntoView()
          // var closestElement = element.closest(".animation-anchor");
          // closestElement.scrollIntoView()
          // console.log(closestElement)
          // window.scrollTop=0
        }
      }

    },1)
  }

  return (

    <GlobalThemeProvider>
      <BrowserRouter history={browserHistory}>
        <Switch>
          <Route path='/' exact render={(props) => {
            return <LandingPage {...props} />
          }}>
          </Route>
          <Redirect to='/' />
        </Switch>
      </BrowserRouter>
    </GlobalThemeProvider>
  )
}

export default App
