import React from "react"
import tw from "twin.macro"
// import styled from "styled-components"
import styled, { css } from "styled-components/macro" //eslint-disable-line

import LogoImage from "images/pointant/p5_app icon.png"
import { ReactComponent as SvgDecoratorBlob1 } from "images/svg-decorator-blob-9.svg"
import appStoreImage from 'images/pointant/p5_appstore_V2.png'
import googlePlayImage from 'images/pointant/p5_googleplay_V2.png'
import findUsOnFacebookImage from 'images/pointant/findusonfacebooklogo.png'
import { NormalGridLayout, Paragraph } from "./Misc"
const CustomersLogoStrip = styled.div`
  ${tw`mt-2 lg:mt-0 text-pointant_white flex self-center lg:self-end lg:w-1/2  items-end justify-end `}
  div{
    ${tw`mx--2`}
  }
  p {
    ${tw`uppercase text-sm lg:text-xs tracking-wider font-bold`}
  }
  img {
    ${tw`mt-2 lg:mt-0 mx-0 px-2 xl:px-2 opacity-100 inline max-h-2`}
  }
`


const Container = tw.div`relative bg-pointant_accent text-pointant_white px-8 lg:px-16 pt-12 lg:pt-20 pb-2 lg:pb-8`
const Content = tw.div`max-w-screen-xl mx-auto relative z-10`
const Link = tw.a`border-b-2 border-transparent hocus:border-gray-100 pb-1 transition duration-300`

// const ThreeColRow = tw.div`flex flex-col md:flex-row items-center justify-around`

const LogoContainer = tw.div`flex self-center lg:self-start items-start justify-start lg:w-1/2 mt-2 lg:mt-0 `
const LogoImg = tw.img`w-8`
const LogoText = tw.h5`ml-2 text-xl! font-black tracking-wider text-pointant_white`

const CopywrightNotice = tw(Paragraph)`text-center text-base! lg:text-lg! mt-8 font-medium text-pointant_dark`

const DecoratorBlobContainer = tw.div`absolute inset-0 overflow-hidden rounded-lg`
const DecoratorBlob1 = tw(
  SvgDecoratorBlob1
)`absolute top-0 left-0 w-80 h-80 transform -translate-x-20 -translate-y-32 text-primary-700 opacity-50`
const DecoratorBlob2 = tw(
  SvgDecoratorBlob1
)`absolute bottom-0 right-0 w-80 h-80 transform  translate-x-32 translate-y-48 text-primary-700 opacity-50`

// const DownloadText = tw(Paragraph)`text-pointant_dark my-2 text-lg! lg:text-xl!`
const DownloadRow = tw(NormalGridLayout)`items-center`


function Copyright() {
  return (
    <CopywrightNotice>
      {'Copyright © 2014-'}
      {new Date().getFullYear()}
      {' '}
      <Link color="inherit" href="https://www.animaetech.com/">
        {'Animae Technologies Limited.'}
      </Link>
      {' '}
      {'All Rights Reserved.'}
    </CopywrightNotice>
  )
}

const Footer = () => {
  return (
    <Container>
      <Content>
        <DownloadRow>

          <LogoContainer>
            <LogoImg src={LogoImage} />
            <LogoText>Pointant Class</LogoText>
          </LogoContainer>
          <CustomersLogoStrip>
            <div >
              <a target="_blank" rel="noreferrer" href='https://apps.apple.com/us/app/id1528318712'>
                <img src={appStoreImage} alt="Pointant Class Apple App"/>
              </a>
              <a target="_blank" rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.animae.pointant_class_app_admin">
                <img src={googlePlayImage} alt="Pointant Class Android App"/>
              </a>
              <a target="_blank" rel="noreferrer" href="https://www.facebook.com/PointantClass">
                <img src={findUsOnFacebookImage} alt="Find us on facebook"/>
              </a>
            </div>
          </CustomersLogoStrip>
        </DownloadRow>
      <Copyright/>
      </Content>
      <DecoratorBlobContainer>
        <DecoratorBlob1 />
        <DecoratorBlob2 />
      </DecoratorBlobContainer>
    </Container>
  )
}
export default Footer