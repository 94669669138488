import React from "react"
import styled_component from "styled-components"
import tw from "twin.macro"


import { ReactComponent as SvgDecoratorBlob1 } from "../../images/svg-decorator-blob-1.svg"
import AdminPanelImage from "../../images/p2_img1_V2.png"
import { Heading, Image, ImageColumn, Section, TextColumn, ReverseGridLayout, IllustrationContainer, LinkContainer, Link } from "./Misc"

const DecoratorBlob1 = styled_component(SvgDecoratorBlob1)`
  ${tw`pointer-events-none opacity-5 absolute left-0 bottom-0 h-64 w-64 transform -translate-x-2/3 -z-10`}
`

const MainLink = tw(Link)`bg-pointant_main mr-4 lg:mr-8`
// const AccentLink = tw(Link)`bg-pointant_accent`
const MyHeading = tw(Heading)`text-pointant_dark`
const Banner = ({ 
  id="banner", 
  title="學生積分管理系統",
  subtitle="電子化管理方便又輕鬆",
  // link1Text="立即註冊",
  // link1URL="https://app.class.pointant-app.com/sign-in",
  link1Text="聯繫顧問",
  link1URL="/#contactus",
  imgSrc=AdminPanelImage,
  imgAlt="Pointant Class Admin Panel"
}={}) => {
  return (
    <>
      <Section id={id}>
        <ReverseGridLayout>
          <TextColumn>
            <Heading>
              {title}
            </Heading>
            <MyHeading>
              {subtitle}
            </MyHeading>
            <LinkContainer>
              <MainLink
                href={link1URL}
              >
                {link1Text}
              </MainLink>
              {/* <AccentLink
                href={link2URL}
                >
                {link2Text}
              </AccentLink> */}
            </LinkContainer>
          </TextColumn>
          <ImageColumn>
            <IllustrationContainer>
              <Image src={imgSrc} alt={imgAlt} />
            </IllustrationContainer>
          </ImageColumn>
        </ReverseGridLayout>
        <DecoratorBlob1 />
      </Section>
    </>
  )
}

export default Banner