import React from "react"
import PunchCard from "./PunchCard.js"
import Points from "./Points.js"
import { DarkSection, Heading } from "./Misc.js"

const MainFeatures = ({ id="features",title="主要功能" }) => {
  return (
    <DarkSection id={id} data-aos="fade-in">
      <Heading>{title}</Heading>
      <Points/>
      <PunchCard/>
    </DarkSection>
  )
}

export default MainFeatures