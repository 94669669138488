import React from "react"
import tw from "twin.macro"
import { Heading, Section, LayoutContainer, Paragraph, ImageColumn } from "./Misc"
import PlanCard from "./PlanCard"

const defaultPlans = [
  {
    name: "基本方案",
    targetAudience: "適合小型教育機構",
    contactUs: "聯繫顧問",
    studentAccountLimit: "最多500個學生帳戶",
    teacherAccountLimit: "無限量老師帳戶",
    appVersions: "網頁版及手機應用程式後台管理系統",
    extraFeatures: []
  },
  {
    name: "進階方案",
    targetAudience: "適合中型教育機構",
    contactUs: "聯繫顧問",
    studentAccountLimit: "最多1,000個學生帳戶",
    teacherAccountLimit: "無限量老師帳戶",
    appVersions: "網頁版及手機應用程式後台管理系統",
    extraFeatures: []
  },
  {
    name: "旗艦方案",
    targetAudience: "適合大型教育機構\n可度身訂造方案",
    contactUs: "聯繫顧問",
    studentAccountLimit: "無限學生帳戶",
    teacherAccountLimit: "無限量老師帳戶",
    appVersions: "網頁版及手機應用程式後台管理系統",
    extraFeatures: [
      "客製化設計",
      "第三方平台串接",
      "API 串接",
      "SSO 單一帳戶登入"
    ]
  },
]
const PlainText = tw.span`text-pointant_dark`
const Column = tw(ImageColumn)`self-stretch`
const Plans = ({ 
  id="plans", 
  title="費用介紹", 
  subtitle="管理系統方案", 
  description="一年預付價格",
  reminder="註：以上方案費用不包括多功能自助服務機。",
  plans=defaultPlans,
}) => {
  return (
    <>
      <Section id={id}>
        <Heading>{title}<PlainText>{subtitle}</PlainText></Heading>
        <Paragraph><PlainText>{description}</PlainText></Paragraph>
        
        <LayoutContainer>
            {
              plans.map((plan,index)=>(
                <Column key={index}>
                  <PlanCard
                    name={plan.name}
                    index={index}
                    targetAudience={plan.targetAudience}
                    pricePrepaidOneYear={plan.pricePrepaidOneYear}
                    pricePrepaidOneYearPreferential={plan.pricePrepaidOneYearPreferential}
                    studentAccountLimit={plan.studentAccountLimit}
                    teacherAccountLimit={plan.teacherAccountLimit}
                    appVersions={plan.appVersions}
                    extraFeatures={plan.extraFeatures}
                  />
                </Column>
              ))
            }
          </LayoutContainer>
        <Paragraph>{reminder}</Paragraph>
      </Section>
    </>
  )
}

export default Plans