import React from "react"
import ReportScreensImage from "../../images/pointant/report_screens.png"
import { IllustrationContainer, Image, ImageColumn, NormalGridLayout, Paragraph, SubHeading, Subsection, TextColumn } from "./Misc.js"
const Points = ({ title="積分系統",text="老師可透過系統管理積分規則，並根據學生的學習進度及活動參與度派發積分，當學生累積足夠的積分後可使用積分兌換獎勵，藉以鼓勵學生主動學習及積極參與校園活動。學生可利用多功能自助服務機掃瞄學生證上的條碼，即可查詢積分紀錄。",imgSrc=ReportScreensImage,imgAlt="Multiple Point Reports"}) => {
  return (
    <>
      <Subsection>
        <NormalGridLayout>
          <ImageColumn>
            <IllustrationContainer>
              <Image src={imgSrc} alt={imgAlt} />
            </IllustrationContainer>
          </ImageColumn>
          <TextColumn>
            <SubHeading>
              {title}
            </SubHeading>
            <Paragraph>
              {text}
            </Paragraph>
          </TextColumn>
        </NormalGridLayout>
      </Subsection>
    </>
  )
}

export default Points