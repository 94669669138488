import { createMuiTheme, colors, ThemeProvider } from "@material-ui/core"

const MuiThemeProvider = props => {

    const { children } = props

    const theme = createMuiTheme({
        palette: {
            primary: {
                main: '#ffbd00',
                100: '#ffbd00',
                200: '#ffbf05',
                300: '#ffc10f',
                400: '#ffc00d',
                500: '#e6ad0c',
                600: '#cc9a0a',
                700: '#b38609',
                800: '#997308',
                900: '#805f04',
            },
            secondary: {
                main: '#243E63',
                100: '#7c8ba1',
                200: '#667892',
                300: '#506582',
                400: '#3a5173',
                500: '#243E63',
                600: '#203859',
                700: '#1d324f',
                800: '#192b45',
                900: '#16253b',
            },
            text: {
                primary: colors.blueGrey[900],
                secondary: colors.blueGrey[300]
            }
        },
        // typography: typography,
        spacing: 4,
    })

    return (
        <ThemeProvider theme={theme}>
            {children}
        </ThemeProvider>
    )
}

export default MuiThemeProvider
